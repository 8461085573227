<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="学员姓名">
              <a-input placeholder="学员姓名/手机号" v-model="search.name" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="用户组">
              <a-select
                v-model="search.groupIds"
                allowClear
                @change="groupChanged"
              >
                <a-select-option
                  :value="info.id"
                  v-for="(info, index) in grouplist"
                  :key="index"
                  >{{ info.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="3" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          onChange: onRowChange,
          selectedRowKeys: selectRows,
          getCheckboxProps: getCheckboxProps,
        }"
        :locale="{ emptyText: '暂无数据' }"
      >
      </a-table>
      <a-form-item :wrapper-col="{ span: 19, offset: 5 }">
        <pxkeji-btn
          name="全选"
          type="primary"
          @click="selectAllBack"
          class="cancelBtn"
        />
        <pxkeji-btn
          name="选中用户"
          type="primary"
          class="nextBtn"
          @click="selectBack"
        />
      </a-form-item>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "fullName",
    align: "center",
    title: "学员名称",
  },
  {
    dataIndex: "telephone",
    align: "center",
    title: "手机号",
  },
  {
    dataIndex: "idCard",
    align: "center",
    title: "身份证号",
  },
  // {
  //   dataIndex: "enterprise.name",
  //   key: "enterpriseName",
  //   align: "center",
  //   title: "所属单位",
  // },
  {
    dataIndex: "extension.groupName",
    align: "center",
    title: "管理组名称",
  },
];
let _this;
export default {
  name: "messageSelectUser",
  props: {
    selectIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
      },
      search: {
        name: "",
        company: "",
        groupIds: "",
        studentIds: "",
      },
      selectRows: [], //选中项编号
      allSelectRows: this.selectIds,
      isloading: false,
      grouplist: [],
    };
  },
  components: {},
  mounted: function () {
    _this = this;
    _this.getgrouplist();
    _this.GetList();
  },
  methods: {
    groupChanged(v) {
      _this.search.groupIds = v;
    },
    getgrouplist() {
      _this.isloading = true;
      _this.$api.usergroup
        .getAll()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.grouplist = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    getCheckboxProps(record) {
      let checked = false;
      let currentlist = _this.allSelectRows;
      for (let i in currentlist) {
        if (currentlist[i] == record.id) {
          checked = true;
          break;
        }
      }
      return {
        defaultChecked: checked,
      };
    },
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.student
        .getStudentPageListNoAuth(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          { name: _this.search.name, groupId: _this.search.groupIds }
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.datas.forEach((item) => {
              if (item.extension) {
                item.extension = JSON.parse(item.extension);
              }
            });
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    async getStudnetIdsList() {
      let res = await _this.$api.student.getStudentList({
        name: _this.search.name,
        enterpriseName: _this.search.company,
        groupId: _this.search.groupIds,
      });
      if (res.errorCode == this.$msg.responseCode.success) {
        _this.allSelectRows = res.data.map((item) => item.id);
      } else {
        _this.$message.error(res.errorMsg);
      }
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
      _this.allSelectRows = selectedRowKeys;
    },
    searchList() {
      //清空选择
      for (let key in _this.allSelectRows) {
        delete _this.allSelectRows[key];
      }
      _this.selectRows = [];
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    selectAllBack() {
      _this.getStudnetIdsList();
      _this.selectBack();
    },
    selectBack() {
      let allids = [];
      for (let row in _this.allSelectRows) {
        if (allids.indexOf(_this.allSelectRows[row]) == -1) {
          allids.push(_this.allSelectRows[row]);
        }
      }
      _this.$emit("callbackMethod", allids);
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.nextBtn {
  margin-left: 20px;
}
</style>

