<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class>
      <a-form-model
        ref="messageForm"
        :rules="rules"
        :model="info"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="标题" prop="title">
          <a-input placeholder="请输入" v-model="info.title"></a-input>
        </a-form-model-item>
        <a-form-model-item label="消息类型" prop="type">
          <a-radio-group v-model="info.type">
            <a-radio value="shortmessage">站内信</a-radio>
            <a-radio value="sms">短信</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="内容" prop="content">
          <a-textarea
            placeholder="请输入内容"
            :autoSize="{ minRows: 4, maxRows: 6 }"
            v-model="info.content"
          />
        </a-form-model-item>
        <a-row :gutter="24">
          <a-col :offset="1" :span="8">
            <pxkeji-btn
              name="选择用户"
              class="pxkeji_basic_btn"
              @click="selectStudent()"
              type="primary"
            />
          </a-col>
        </a-row>
        <a-table
          rowKey="id"
          size="small"
          bordered
          :columns="list.columns"
          :pagination="list.pagination"
          @change="onPageChange"
          :dataSource="list.datas"
          :loading="isloading"
          :locale="{ emptyText: '暂无数据' }"
        >
        </a-table>
        <a-form-model-item :wrapper-col="{ span: 5, offset: 4 }">
          <pxkeji-btn name="发送" type="primary" @click="handleSubmit" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="选择用户"
      v-model="userList.visible"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      width="80%"
    >
      <selectUser :selectIds="studentList" @callbackMethod="selectUserBack" />
    </a-modal>
  </a-spin>
</template>

<script>
import selectUser from "./messageSelectUser.vue";

const columns = [
  {
    dataIndex: "fullName",
    key: "fullName",
    align: "center",
    title: "学员名称",
  },
  {
    dataIndex: "telephone",
    key: "telephone",
    align: "center",
    title: "手机号",
  },
  {
    dataIndex: "idCard",
    key: "idCard",
    align: "center",
    title: "身份证号",
  },
  {
    dataIndex: "enterprise.name",
    key: "enterpriseName",
    align: "center",
    title: "所属单位",
  },
  {
    dataIndex: "extensions.groupName",
    key: "groupName",
    align: "center",
    title: "所属管理组",
  },
];
let _this;
export default {
  name: "messageInfo",
  props: {
    id: String,
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      info: {
        userIds: [],
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      userList: {
        visible: false,
      },
      studentList: [],
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          total: 0,
          current: 1, //页码
          pageSize: 10, //页容
        },
      },
    };
  },
  components: {
    selectUser,
  },
  mounted() {
    _this = this;
  },
  methods: {
    selectStudent() {
      _this.userList.visible = true;
    },
    GetStudentList() {
      //获取列表
      _this.isloading = true;
      _this.$api.student
        .getStudentPageListNoAuth(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          { studentIds: _this.studentList }
        )
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    selectUserBack(selectStudentIds) {
      _this.studentList = selectStudentIds;
      if (selectStudentIds && selectStudentIds.length > 0) {
        _this.GetStudentList();
      }
      _this.userList.visible = false;
    },
    handleSubmit() {
      _this.$refs.messageForm.validate((valid) => {
        if (valid) {
          if (_this.studentList.length == 0) {
            _this.$message.error("请选择用户");
            return;
          }
          //新增
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          _this.$api.message
            .save(
              _this.info.content,
              _this.info.type,
              _this.studentList.join(),
              _this.info.title
            )
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        }
      });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.GetStudentList();
    },
  },
};
</script>
<style>
</style>