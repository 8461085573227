<template>
  <div>
    <div class="pxkeji-right-box">
      <div style="margin-bottom: 10px">
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo('0')"
          type="primary"
          :roles="['消息-发送']"
        />
        <pxkeji-btn
          name="批量删除"
          @click="batchDelete"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          :roles="['消息-删除']"
        />
      </div>
      <div>
        <a-table
          rowKey="id"
          size="small"
          bordered
          :columns="list.columns"
          :pagination="list.pagination"
          @change="onPageChange"
          :dataSource="list.datas"
          :loading="isloading"
          :rowSelection="{
            type: 'checkbox',
            onChange: onRowChange,
            selectedRowKeys: selectRows,
          }"
          :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: list.tableHeight }"
        >
          <span slot="action" slot-scope="action">
            <a-popconfirm @confirm="delInfo(action.id)">
              <div slot="title">是否确认删除此广告？</div>
              <a-icon slot="icon" type="question-circle-o" />
              <pxkeji-btn
                name="删除"
                size="small"
                type="danger"
                :loading="isloading"
                :roles="['消息-删除']"
              />
            </a-popconfirm>
          </span>
        </a-table>
      </div>

      <a-modal
        :title="addedit.title"
        v-model="addedit.visible"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
        width="1100px"
      >
        <MessageInfo :id="addedit.id" @callbackMethod="save" />
      </a-modal>
    </div>
  </div>
</template>
<script>
import MessageInfo from "./messageInfo.vue";
const columns = [
  {
    dataIndex: "title",
    key: "title",
    align: "center",
    title: "标题",
    width: 200,
  },
  {
    dataIndex: "body",
    key: "body",
    align: "center",
    title: "内容",
    width: 300,
  },
  {
    dataIndex: "time",
    key: "time",
    align: "center",
    title: "发布时间",
    ellipsis: true,
    width: 110,
  },
  {
    dataIndex: "studentName",
    key: "studentName",
    align: "center",
    title: "接收人",
    width: 200,
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "messageList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      selectRows: [], //选中项编号
      isloading: false,
      addedit: {
        title: "",
        id: 0,
        visible: false,
      },
    };
  },
  components: {
    MessageInfo,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 252;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.clearRows();
      _this.$api.message
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize
        )
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      _this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(id) {
      //打开弹出框
      _this.addedit.visible = true;
      _this.addedit.title = id == 0 ? "新增" : "编辑";
      _this.addedit.id = id;
    },
    /** 批量删除 */
    batchDelete() {
      _this.$confirm({
        title: "提示",
        content: `您确定要删除已选择的消息吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.delInfo(_this.selectRows.join(","));
        },
        onCancel() {},
      });
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.message
        .deleteByIds(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    //子组件传值
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.items {
  /* margin: 15px 0; */
  border-bottom: 1px #e8e8e8 solid;
  padding: 20px 0;
  color: rgba(0, 0, 0, 0.85);
}
.selectStyle {
  width: 200px !important;
  display: inline-block;
  margin-left: 5px;
}
.rechargeIpt {
  width: 120px;
  margin: 0 20px 0 5px;
}
.costIpt {
  width: 180px;
  margin: 0 20px 0 5px;
}
.templateItem {
  float: left;
  margin-left: 10px;
  margin-right: 20px;
}
.temBtn {
  cursor: pointer;
  color: #1890ff;
}
.itemBtns .ant-btn-primary {
  margin: 0 5px;
}
</style>

